.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row {
    border-bottom: 2px solid #0000001f;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiDataGrid-iconSeparator {
    color: #00000017;
}

.MuiDataGrid-columnHeaders {
    background: #edf2f2;
}

.d-flex.justify-content-between.align-items-center {
    display: flex;
}

.recentbooking {
    /* border: 1px solid #00000036; */
    box-shadow: 3px 3px 9px 0px #00000026;
    border-radius: 10px;
}



.cst_Admin_Info h1 {
    font-family: 'system-ui' !important;
    font-size: 23px;
    margin: 0;
    color: #c1761f;
}

.page_title_individual.page_title_individual h1 {
    color: #16595a;
    font-family: sans-serif;
    text-transform: uppercase;
    font-size: 30px;
}

.page_title_individual.page_title_individual h1 span {
    color: #c77e23;
}

.recentbooking ::-webkit-scrollbar {
    display: none !important;
}

.MuiDataGrid-root {
    border-radius: 10px;
}

.MuiDataGrid-columnHeaders {
    border-radius: 10px 10px 0 0;
}

a.unset_logo_property>img {
    width: 120px;
    position: absolute;
    top: 8px;
}

.hello_box_shadow {
    box-shadow: 2px 2px 9px 0px #00000030;
}

span.signup_nmow_dnt_act {
    color: #c77e23;
}

.css-4jnixx-MuiStack-root {
    flex-direction: column;
}

.wrapper_box_container {
    margin: 13px 0 20px;
    display: grid;
    grid-template-columns: auto;
    background: #ffffff;
    padding: 20px 0;
    border-radius: 10px;
    border: 1px solid #8bacad75;
}

.wrapper_box_container .contain_boxes {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.wrapper_box_container .contain_boxes div {
    display: flex;
    justify-content: space-between;
    gap: 0;
    margin: 04;
    padding: 0 10px;

}

.wrapper_box_container .contain_boxes div p {
    width: 95px;
    margin: 0;
    font-size: 13px;
}

.box_details_wrapper {
    flex-direction: column;
}

.give_psce_x_cst {
    padding-bottom: 13px;
    padding-top: 3px;
}

p.cnt_Cst_wrp {
    padding: 10px;
    font-size: 16px !important;
    font-weight: 500;
    color: #16595a;
    border-radius: 10px;
    margin-top: 2% !important;
    border: 1px solid #0003;
    background: #fafafa;
}

p.show_error_Enter {
    margin: 0;
    font-size: 14px;
    padding-left: 3px;
    color: #c77e23;
}

.wrapper_box_container .contain_boxes>p {
    font-size: 18px;
    color: #16595a;
    border-bottom: 1px solid #16595a42;
    margin: 0;
    margin-bottom: 16px;
    padding: 0 16px 16px;
}

.css-12mrxr6-MuiCardContent-root {
    padding: 0px !important;
    padding: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

h6.m-0_x_new_space {
    margin-bottom: 15px;
}

ul.MuiList-root.MuiList-padding.MuiList-subheader {
    padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none !important;
}

.MuiDataGrid-columnHeaders {
    padding-left: 25px !important;
}

.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    padding-left: 25px !important;
}

.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row {
    border-bottom: 2px solid #0000001f !important;
}

.recentbooking.X_dashboard .MuiDataGrid-columnHeaders {
    padding-left: 8px !important;
}

.No_section_visiblity {
    display: none;
}

.distanceField {
    display: flex;
    align-items: stretch;
}

.distanceField p.labelField {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #bdbdbd;
    border-left: none;
    border-radius: 0 10px 10px 0;
    margin: 8px 0;
    background: #c77e23;
    color: #FFFFFF;
}

.MuiAvatar-root.MuiAvatar-circular.MuiChip-icon.MuiChip-iconMedium.MuiChip-iconColorInherit.css-68ujhz-MuiAvatar-root {
    display: none;
}

p.vew_spacial_details_clr:last-child {
    color: #16595a;
}

p.vew_spacial_details_clr b {
    color: #000;
}

.signup_now_verify_email {
    margin: 5px 0 0;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    line-height: 1.2;
    color: #c77e23;
    cursor: pointer;
}

a.signup_now_verify_email.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.varifyOTP input[type="number"] {
    width: 40px !important;
    padding: 5px !important;
    display: block !important;
    background: transparent;
    border: 1.54px solid #c1761f !important;
}

.otp-inputs>div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.otp-inputs>div input[type="number"] {
    padding: 0 0 !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 4px;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    appearance: none;
    -moz-appearance: textfield;
}

.otp-inputs>div {
    gap: 10px;
}

.otp-inputs>div>div {
    width: 290px;
    justify-content: space-between;
}

p.verifiedEmail {
    margin: 0 0;
    display: flex;
    align-items: center;
    gap: 3px;
    color: #16595a;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
    width: -webkit-fill-available !important;
}

.editProfileButton {
    margin: 0;
    transform: translateY(-68px);
    height: 0;
}

.adminProfileInput input {
    -webkit-text-fill-color: #000000 !important;
}

.MuiDataGrid-footerContainer {
    display: none !important;
}

.pagination-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-top: 1px solid var(--gray); */
    margin-top: 1%;
}

.pagination-block .nextui-pagination-highlight--active {
    background-color: #135152;
}

.pagination-block .result-info {
    color: #727272;
}

.pagination-block .result-info span {
    color: #135152;
    font-weight: bold;
}

.pagination-block .nextui-pagination-highlight {
    background-color: #135152;
}

.customPagination {
    align-items: center;
    display: flex;
    gap: 20px;
}

.customPagination .page-size-select {
    display: flex;
    align-items: center;
}

.customPagination .page-size-select p {
    margin: 0;
    font-size: 15px;
    color: #135152;
}

.section_p_20 {
    width: 500px;
    padding: 25px 20px;
}

.MuiCircularProgress-colorPrimary {
    color: #c77e23 !important;
}

/* Css Popover */
.drwdownMenus {
    display: flex;
    grid-gap: 5%;
    gap: 5%;
    padding: 0 0 7%;
    border-bottom: 1px solid #13515212;
    margin-bottom: 7%;
}

button.btn.btn-secondary {
    background-color: #edf2f2;
    color: #000;
    font-size: 16px;
    border-radius: 10px;
    padding: 8px 23px;
}

p.resend-btn span {
    color: #bf8d30;
    cursor: pointer;
}

p.resend-btn {
    color: #000;
    text-align: center;
}

.apply-btn {
    background-color: #135152 !important;
    border-radius: 10px;
    color: #fff !important;
    font-size: 16px;
    font-weight: 300;
    padding: 10px 20px;
    text-transform: none;
    width: 75%;
}

.drwTitle>h2 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    font-family: system-ui;
}

.allStetus .status-item input[type=radio]:checked+label {
    background-color: #135152;
    color: #fff;
}

.allStetus .status-item input[type=radio]#shipped+label:after {
    background-color: #c77e23;
}

.allStetus .status-item input[type=radio]+label {
    background-color: #edf2f200;
    border: 1px solid #edf2f2;
    border-radius: 15px;
    color: #000;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 10px;
    padding: 15px 30px 15px 50px;
    position: relative;
    width: 100%;
}

.allStetus .status-item input[type=radio]#order-place+label:after {
    background-color: #c77e23;
}

.allStetus .status-item input[type=radio]+label:after {
    background: #c77e23;
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 25px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 10px;
}

.allStetus .status-item input[type=radio]+label {
    background-color: #edf2f200;
    border: 1px solid #edf2f2;
    border-radius: 15px;
    color: #000;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 10px;
    padding: 15px 30px 15px 50px;
    position: relative;
    width: 100%;
}

.allStetus .status-item input[type=radio] {
    display: none;
}

.allStetus .status-item {
    width: 47.5%;
}

.allStetus {
    gap: 0.3rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.filter-item:nth-child(2) {
    padding-bottom: 4%;
}

.Recent_Booking_DY_Flex {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.Recent_Booking_DY_Flex p {
    margin: 0;
}

.no_bottom_line_Add {
    padding-bottom: 0;
    border-bottom: 0px;
    padding-top: 0px;
}

.cst_Admin_Info p {
    margin: 0;
    font-size: 12px;
    font-family: system-ui;
}

.cst_Admin_Info {
    padding: 40px 40px 20px 40px;
    font-family: 'system-ui';
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}

.sho_no_data {
    font-size: 20px;
    color: #000;
    text-transform: capitalize;
    font-family: system-ui;
}

.skeleton_row_data {
    display: flex;
    width: 100%;
    gap: 1rem;
}

.Skeleton_data {
    width: 100%;
}

.skeleton_row_data span {
    width: 100%;
    height: 66px;
}

.form-control.otp-inputs {
    padding-top: 10px;
}

button.Logout_now {
    background: #c77e23;
}

button.Keep_me_loged_in:hover,
button.Keep_me_loged_in:focus {
    background: #c77e23;
}

h1.logout_warning_title span {
    color: #c77e23;
}

h1.logout_warning_title {
    text-align: center;
    font-size: 25px;
    font-family: system-ui;
}

.button_for_logout {
    display: flex;
    gap: 1rem;
    justify-content: center;
    padding: 4% 5% 4%;
}

.create_special_node_ p {
    font-size: 14px;
    line-height: 1.5em;
    color: black;
    font-family: 'Roboto';
}

.create_special_node_ p b {
    color: #bf8d30;
    font-size: 18px;
}

.gfc_new_container.payout_history_container > .MuiStack-root {
    display: flex;
    flex-direction: row;
    width: auto;
    padding: 15px !important;
    
}

.gfc_new_container.payout_history_container .Filter_by_date_range .MuiStack-root {
    display: flex;
    flex-direction: row;
    width: auto;
    padding: 15px !important;
}

.filter_btns {
    height: -webkit-fill-available;
    display: flex;
    gap: 1rem;
}

.filter_container {
    display: flex;
    align-items: center;
    padding-right: 2rem;
    gap: 1rem;
}

.Loader_body {
    text-align: center;
    padding: 35px;
}

.Date_piker_icon_Adj div>div button {
    margin: 0;
}

.gfc_new_container.payout_history_container h2 {
    text-transform: uppercase;
    font-size: 25px;
    color: #c77e23;
}

.gfc_new_container.payout_history_container {
    margin-bottom: 30px;
    border-bottom: 1px solid;
    border-color: #eeeeee;
    border-top: 1px solid #eeeeee;
}

.give_psce_x_cst .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    min-width: 100%;
}

.gfc_new_container.payout_history_container.dahboard_pdg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid;
    padding: 0 0 0 15px;
    border-bottom: 1px solid;
    border-color: #b6b6b6;
    background: #ffffff;
    border-radius: 10px;
}

.gfc_new_container.payout_history_container.dahboard_pdg h2 {
    color: #135152;
}

.profile_information_gfc {
    display: flex;
    justify-content: space-between;
    padding: 0% 0 1%;
    border-bottom: 1px solid #00000024;
    margin-bottom: 2%;
}

.profile_information_gfc div > p strong {
    color: #16595a;
    font-size: 18px;
    font-family: 'circular', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
}

.profile_information_gfc div > p {
    color: #bf8d30;
    margin: 0;
    font-size: 18px;
    font-family: 'circular', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
}

@media screen and (max-width: 767px) {
    a.unset_logo_property>img {
        width: 65%;
        position: unset;
        top: 8px;
    }

    h1.logout_warning_title {
        text-align: center;
        font-size: 18px;
        font-family: system-ui;
    }

    .payout_paid_amount.payout_history_x {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 1rem !important;
    }

    .Textual_icon {
        width: 100% !important;
        display: flex;
        grid-gap: 1rem;
        gap: 1rem;
        align-items: center;
        background: #e3ebeb;
        padding: 20px !important;
        border-radius: 10px;
    }

    .Payouts_States {
        padding-bottom: 25px;
    }

    .icon_base_cnt p {
        font-size: 16px;
    }

    .Payouts_States {
        display: flex;
        flex-direction: column;
        align-items: baseline !important;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 30px !important;
        gap: 1.5rem;
    }

    .page_title_individual.page_title_individual h1 {
        color: #16595a;
        font-family: sans-serif;
        text-transform: uppercase;
        font-size: 18px;
    }

    .gfc_cst_elemenbts_wrapper .payout_paid_amount h1 {
        color: #c77e23;
        font-family: sans-serif;
        text-transform: uppercase;
        font-size: 20px !important;
        margin: 0;
    }

    .gfc_new_container {
        width: 100% !important;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 1rem;
        gap: 1rem;
    }

    .search-box .search-input {
        width: 100% !important;
        padding-left: 55px;
        height: 40px;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        background: #cabd9a6b !important;
    }

    .gfc_spacial {
        width: calc(100% - 10px) !important;
        margin: 0 auto;
        height: 100%;
        padding: 5% !important;
        background: #fff;
        border-radius: 10px;
        box-shadow: 1px 2px 5px 0px #0000002e;
    }

    .svg_filter_icons {
        width: -moz-fit-content;
        width: fit-content;
        padding: 10px !important;
        height: 49px !important;
        background: #c77e23;
        display: flex;
        align-items: center;
        border-radius: 5px;
        box-shadow: 1px 2px 3px 0px #0006;
    }

    main.main_container_gfc {
        margin-left: 10px;
        width: calc(100% - 228px);
        padding: 10px 5px !important;
        margin-right: 10px;
        padding: 0 16px;
    }

    .wrapper_box_container .contain_boxes div {
        display: unset;
        width: 100%;
        margin-top: -5%;
        margin-bottom: -4%;
    }

    .wrapper_box_container .contain_boxes div p {
        width: 100%;
    }

    .give_psce_x_cst {
        display: flex;
        gap: 0.8rem;
        padding-top: 6px;
    }

    .pagination-block {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        /* border-top: 1px solid var(--gray); */
        margin-top: 8%;
        align-items: normal;
        flex-direction: column;
    }

    .customPagination {
        align-items: center;
        display: flex;
        grid-gap: 20px;
        gap: 20px;
        justify-content: space-between;
    }

    .section_p_20 {
        width: -webkit-fill-available;
        padding: 25px 20px;
    }

    .allStetus .status-item input[type=radio]+label:after {
        background: #c77e23;
        border-radius: 50%;
        content: "";
        height: 8px;
        left: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
    }

    .allStetus .status-item input[type=radio]+label {
        background-color: #edf2f200;
        border: 1px solid #edf2f2;
        border-radius: 15px;
        color: #000;
        cursor: pointer;
        display: inline-block;
        margin-bottom: 10px;
        padding: 10px 8px 10px 40px;
        position: relative;
        width: 100%;
        font-size: 12px;
    }

    button.btn.btn-secondary {
        background-color: #edf2f2;
        color: #000;
        font-size: 14px;
        border-radius: 15px;
        padding: 5px 23px;
    }

    .gfc_new_container.payout_history_container {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }

    .gfc_new_container.payout_history_container h2 {
        text-transform: uppercase;
        font-size: 20px;
    }

    .gfc_new_container.payout_history_container>.MuiStack-root {
        display: flex;
        flex-direction: column;
        padding: 15px 0 !important;
        width: 100%;
    }

    .gfc_new_container.payout_history_container.dahboard_pdg {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: 1px solid;
        padding: 15px;
    }

}

#standard-weight-helper-text--register {
    font-size: 12px;
    margin: 0;
    line-height: 16px;
    padding-left: 4px;
    padding-top: 5px;
}